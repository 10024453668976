.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.global-app-name {
  font-weight: bold;
}

.login-header {
  height: 10vh;
  display: inline-block;
  text-align: justify;
}

.global-header {
  width: 100%;
  display: flex;
  justify-content: space-between;

  vertical-align: top;
}

.navify-logo {
  font-weight: 700;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: url(./img/login-bg2.svg) no-repeat 50% 60% /
    calc(100vw + 100vh - 100vmin) auto;
  background-color: #f2fcff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: #101010;
}

.App-body {
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px solid #bababa;
  width: 50%;
  padding: 5px 50px 5px;
  border-radius: calc(0.0025 * (100vw + 100vh - 100vmin));
  min-height: 80vh;
  margin-top: 20px;
}

.App-link {
  color: #150cff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-option {
  background-color: #cbcbcb;
  color: #000000;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.tenant-app-option {
  background-color: #cbcbcb;
  color: #000000;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.select-box {
  color: #000000;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.select-box:hover {
  background-color: #feffec;
  cursor: pointer;
}

span.tenant-option-alias {
  font-size: calc(10px + 0.5vmin);
  font-style: italic;
}

.float-right {
  float: right;
}

.full-width {
  width: 100%;
}

.tenant-list {
  border: rgb(157, 157, 157) solid 1px;
  margin-bottom: 10px;
}

.app-list {
  border: rgb(157, 157, 157) solid 1px;
  margin-bottom: 10px;
}

.center {
  text-align: center;
}

.inline-block {
  display: inline-block;
}
